/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import React from "react"
import GatsbyRoot from "./gatsby-root"
import "./src/styles/global.css"
import "@fontsource/poppins"

export const wrapRootElement = ({ element }) => {
  return <GatsbyRoot>{element}</GatsbyRoot>
}
